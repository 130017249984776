import { useMemo, forwardRef, ForwardedRef } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  CircularProgress,
  Typography,
  TypographyOwnProps,
} from '@mui/material';

type ButtonVariant = 'contained' | 'outlined' | 'text';
type ButtonSize = 'small' | 'large';

export interface ButtonProps extends Omit<MuiButtonProps, 'color'> {
  variant?: ButtonVariant;
  size?: ButtonSize;
  isLoading?: boolean;
  isSelected?: boolean;
}

type ActionColors = {
  default: string;
  hovered: string;
  focused: string;
  disabled: string;
  selected: string;
};

type FontColors = {
  default: string;
  disabled: string;
};

type ColorsForButton = {
  border: ActionColors;
  background: ActionColors;
  font: FontColors;
};

type SizesForButton = {
  paddingX: string;
  paddingY: string;
  typographyVariant: TypographyOwnProps['variant'];
  spinnerSize: string;
};

const Button = forwardRef(
  (props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
      children,
      variant = 'contained',
      size = 'large',
      isLoading,
      isSelected,
      sx,
      ...rest
    } = props;

    const theme = useTheme();

    // keep this in the component because story file is not able to import theme
    const buttonColorMap: Record<ButtonVariant, ColorsForButton> = useMemo(
      () => ({
        contained: {
          border: {
            default: theme.palette.primary.main,
            hovered: theme.palette.border.active,
            focused: theme.palette.border.actionable,
            disabled: theme.palette.border.disabled,
            selected: theme.palette.border.active,
          },
          background: {
            default: theme.palette.primary.wash,
            hovered: theme.palette.primary.main,
            focused: theme.palette.primary.main,
            disabled: theme.palette.background.disabled,
            selected: theme.palette.primary.main,
          },
          font: {
            default: theme.palette.text.primary,
            disabled: theme.palette.text.disabled,
          },
        },
        outlined: {
          border: {
            default: theme.palette.border.actionable,
            hovered: theme.palette.border.active,
            focused: theme.palette.border.actionable,
            disabled: theme.palette.background.disabled,
            selected: theme.palette.border.active,
          },
          background: {
            default: theme.palette.background.paper,
            hovered: theme.palette.primary.wash,
            focused: theme.palette.background.paper,
            disabled: theme.palette.background.disabled,
            selected: theme.palette.primary.wash,
          },
          font: {
            default: theme.palette.text.primary,
            disabled: theme.palette.text.disabled,
          },
        },
        text: {
          border: {
            default: 'transparent',
            hovered: 'transparent',
            focused: 'transparent',
            disabled: 'transparent',
            selected: 'transparent',
          },
          background: {
            default: 'transparent',
            hovered: theme.palette.primary.wash,
            focused: 'transparent',
            disabled: 'transparent',
            selected: theme.palette.background.default,
          },
          font: {
            default: theme.palette.text.primary,
            disabled: theme.palette.text.disabled,
          },
        },
      }),
      [theme],
    );

    const colorsByVariant = useMemo(
      () => buttonColorMap[variant],
      [buttonColorMap, variant],
    );

    const sizesByButtonSize = useMemo(() => {
      const map: Record<ButtonSize, SizesForButton> = {
        small: {
          paddingX: '8px',
          paddingY: '4px',
          typographyVariant: 'h6',
          spinnerSize: '16px',
        },
        large: {
          paddingX: '12px',
          paddingY: '8px',
          typographyVariant: 'h5',
          spinnerSize: '20px',
        },
      };
      return map[size];
    }, [size]);

    return (
      <MuiButton
        ref={ref}
        variant={variant}
        disabled={isLoading}
        sx={{
          padding: `${sizesByButtonSize.paddingY} ${sizesByButtonSize.paddingX}`,
          borderRadius: '4px',
          color: colorsByVariant.font.default,
          borderColor: isSelected
            ? colorsByVariant.border.selected
            : colorsByVariant.border.default,
          backgroundColor: isSelected
            ? colorsByVariant.background.selected
            : colorsByVariant.background.default,
          borderWidth: '1px',
          borderStyle: 'solid',
          boxShadow: 'none !important',

          '&:hover': {
            borderColor: colorsByVariant.border.hovered,
            backgroundColor: colorsByVariant.background.hovered,
            boxShadow: 'unset',
          },
          '&:focus': {
            borderColor: colorsByVariant.border.focused,
            backgroundColor: colorsByVariant.background.focused,
          },
          '&.Mui-disabled': {
            color: colorsByVariant.font.disabled,
            backgroundColor: colorsByVariant.background.disabled,
          },
          '& .MuiButton-endIcon, & .MuiButton-startIcon': {
            opacity: isLoading ? 0 : 1,
          },
          ...sx,
        }}
        {...rest}
      >
        {typeof children === 'string' || props.title ? (
          <Typography
            variant={sizesByButtonSize.typographyVariant}
            style={{
              opacity: isLoading ? 0 : 1,
            }}
          >
            {children || props.title}
          </Typography>
        ) : (
          children
        )}
        {isLoading && (
          <CircularProgress
            color='neutral'
            size={sizesByButtonSize.spinnerSize}
            sx={{
              position: 'absolute',
              transform: 'translate(-50%, -50%) !important',
              left: '50%',
              top: '50%',
            }}
          />
        )}
      </MuiButton>
    );
  },
);

Button.displayName = 'Button';

export default Button;
